<template>
    <div>
        <va-card>
            <template slot="header">
              <va-icon
                name="fa fa-cogs mr-3"
                color="success"
              />
              <h5 class="mt-0 mb-0">
                Create new Closed Caption for Training (CC)
              </h5>
            </template>
              <div class="d-flex flex-center">
                  <div class="flex md4 sm6 xs12">
                      <va-checkbox
                        v-model="isMultiple"
                        label="Multiple Files"
                        class="mb-3"
                      />
                      <va-checkbox
                        v-model="overwriteFiles"
                        label="Overwrite Files"
                        class="mb-3"
                      />
                      <va-input
                      v-model="fileName"
                      v-show="!isMultiple"
                      required
                      placeholder="Name of the File (.txt is optional)"
                      >
                      <va-icon
                        slot="prepend"
                        color="gray"
                        name="fa fa-envelope-o"
                      />
                      </va-input>
                    
                      <div class="flex flex-center" v-show="uploadedMP3.length >= 5">
                          <va-button
                            @click="createCC"
                            :disabled="!allRequirementsMet"
                          >
                            <i
                              class="va-icon fa fa-file-audio-o"
                              style="margin-right: 1em;"
                            />  Generate CC
                          </va-button>
                      </div>

                      <!-- Unique Files -->
                      <va-file-upload
                        class="flex xs12"
                        dropzone
                        :fileTypes="acceptedFiles"
                        type="single"
                        v-if="!isMultiple"
                        v-model="uploadedMP3"
                        @input="uploadFile"
                      />

                      <!-- Multiple Files -->
                      <va-file-upload
                        class="flex xs12"
                        dropzone
                        :fileTypes="acceptedFiles"
                        v-if="isMultiple"
                        v-model="uploadedMP3"
                        @input="uploadFile"
                      />

                      <div class="flex flex-center" v-show="uploadedMP3.length < 5">
                          <va-button
                            @click="createCC"
                            :disabled="!allRequirementsMet"
                          >
                            <i
                              class="va-icon fa fa-file-audio-o"
                              style="margin-right: 1em;"
                            />  Generate CC
                          </va-button>
                      </div>
                  </div>
                </div>
        </va-card>
    </div>
  </template>
  <script>
  import {debounce} from 'lodash';
  import moment from 'moment';
  import firebase from 'firebase';
  import LogCreator from "../../class/LogCreator"
  import axios from '@/scripts/interceptor.js'
  import { is } from '@babel/types';
  export default {
    data() {
      return {
        patient: "",
        question: "",
        CurrentPR: 1,
        isMultiple: false,
        overwriteFiles: false,
        currentOutput: [],
        patientList: [],
        questionList: [],
        fileName: "",
        uploadedMP3: [],
        acceptedFiles: [
          "audio/mpeg"
        ],
        loading: false,
      };
    },
    computed: {
      allRequirementsMet(){
        if(!this.isMultiple){
          if(this.fileName && this.uploadedMP3.length > 0){
            return true;
          }
        }else{
          if(this.uploadedMP3.length > 0){
            return true;
          }
        }
      }
    },
    methods: {
      updateFileName(){
        this.fileName = this.question.value + ".txt";
      },
      async uploadFile(files) {
        if (files.length === 0) {
          return;
        }
        const fileMap = new Map(); // Use a Map to track duplicate files
        let totalSize = 0;
        for (const file of files) {
          totalSize += file.size;
          // Check for duplicate files
          if (fileMap.has(file.name)) {
            fileMap.get(file.name).push(file);
          } else {
            fileMap.set(file.name, [file]);
          }
        }
        let duplicated = false;
        for (const filesArray of fileMap.values()) {
          if (filesArray.length > 1) {
            duplicated = true;
            break;
          }
        }
        if (duplicated) {
          this.$swal.fire({
            icon: 'warning',
            title: 'Oops! An error occurred',
            text: "One or more files are duplicated. The duplicated files were removed."
          });
          this.uploadedMP3.pop();
          return;
        }
        if (totalSize > 10000000) {
          this.$swal.fire({
            icon: 'error',
            title: 'Oops! An error occurred',
            text: "One or more files are too big. Please, try again with smaller files or fewer files."
          });
          this.uploadedMP3.pop();
          return;
        }
        //get file name 
        this.fileName = files[files.length - 1].name.replace(".mp3", ".txt");
      },
      convertToSRT(json){
            //convert json to srt
            let srt = "";
            let counter = 1;
            for (let item of json){
              let Stime = item.startTime * 1000;
              let date = new Date(Stime);
               //Format 00:00:00.000
              let startTime = date.getUTCHours().toString().padStart(2, '0') + ":" + date.getUTCMinutes().toString().padStart(2, '0') + ":" + date.getUTCSeconds().toString().padStart(2, '0') + "." + date.getUTCMilliseconds().toString().padStart(3, '0');
              let Etime = item.endTime * 1000;
              let date2 = new Date(Etime);
              //Format 00:00:00.000
              let endTime = date2.getUTCHours().toString().padStart(2, '0') + ":" + date2.getUTCMinutes().toString().padStart(2, '0') + ":" + date2.getUTCSeconds().toString().padStart(2, '0') + "." + date2.getUTCMilliseconds().toString().padStart(3, '0');

              srt += counter + "\n";
              srt += startTime + " --> " + endTime + "\n";
              srt += item.ref + "\n\n";
              counter++;
            }
            return srt;
      },
      async ConvertFile(file = false){
          //get the last item of array, because files are added continuously
          const currentItem = !file ? this.uploadedMP3[this.uploadedMP3.length - 1] : file;
          //generate random hash then add it to the current_TIME
          let hash =  (Math.random().toString(36).substring(7)) + Math.round(Date.now() / 1000)
          //save file in current storage bucket that holds temp folder
          const ref = firebase.storage().ref().child('/Temp/' + hash);
          const imageURL = await ref.put(currentItem).then(async snapshot => {
            return snapshot.ref.getDownloadURL((url) => {
              return url;
            })
          })
          //call user function 
          return await axios.post(window.firebaseURL + `api/admin/Validate`, {
            fileHash:  hash,
            fileName: currentItem.name,
            fileURL: imageURL
          }).then((result)=>{ 
            return result.data
          }).catch((error)=>{
            this.$swal.fire({
              icon: 'error',
              title: 'Oops! An error occurred',
              text: error.message
            })
          })
      },
      async SaveOutput(response, fileName = false){
        //convert the response to SRT format and save the file in current dir
        let srt = "";
        this.currentOutput = response.data.info;
        try{
          srt = this.convertToSRT(response.data.info);
        }
        catch{
          this.$swal.fire({
            icon: 'error',
            title: 'Oops! An error occurred',
            text: "Error converting to SRT"
          })
          return;
        }
        let fileRawName = fileName ? fileName : this.fileName;
        //save in Captionings folder
        let directory = firebase.storage().ref().child(`/Captionings/Demonstration/English/${fileRawName}`);
        //check if file exists
        let fileExists = await directory.getDownloadURL().then((url)=>{
          return true;
        }).catch((error)=>{
          return false;
        })
        //if file exists and overwrite is false, then return
        if(fileExists && !this.overwriteFiles){
          this.$swal.fire({
            icon: 'error',
            title: 'Your file already exists',
            text: "You don't have enabled the overwrite option and one or more files already exists, please enable the overwrite option or delete the files that already exists and try again."
          })
          throw new Error("Multiple files already exists and overwrite is disabled")
        }
        let textURL = await directory.putString(srt, 'raw', {contentType: 'text/plain'}).then(async snapshot => {
          return snapshot.ref.getDownloadURL((url) => {
            return url;
          })
        })
        this.textURL = textURL;

        return {
          texturl: textURL,
          json: response.data.info
        }
      },
      async createCC() {
        try {
          this.$swal.fire({
            icon: 'info',
            title: 'Saving & Converting',
            html: `<CurrentCount></CurrentCount> Please wait while we convert your file to <b>.TXT</b>. <br/> <b>This may take a while.</b>`,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: async () => {
                this.$swal.showLoading();
                //if not multiple, then convert and save
                if(!this.isMultiple){
                  let response = await this.ConvertFile();
                  await this.SaveOutput(response)
                  this.$swal.clickConfirm();
                }
                //if multiple, then convert each and save
                else{
                  //run each file
                  for await (const file of this.uploadedMP3) {
                    let warning = `Converting file <b>${this.CurrentPR}</b> of <b>${this.uploadedMP3.length}</b><br/>`;
                    //update current swal text as html to show current progress
                    this.$swal.getContent().querySelector('CurrentCount').innerHTML = warning;
                    let response = await this.ConvertFile(file);
                    let fileName = file.name.replace(".mp3", ".txt");
                    file.output = await this.SaveOutput(response,fileName)
                    this.CurrentPR++;
                  }
                  //force swal to go to .then
                  this.$swal.clickConfirm();
                  this.CurrentPR = 1;
                }
            },
          }).then(async (result) => {
            if(!this.isMultiple){
              let currentTime = firebase.firestore.Timestamp.now()
              let InsertObject = {
                child: "training",
                name: this.fileName.replace(".txt", ""),
                file: {
                  fileURL: this.textURL ? this.textURL : "",
                  fileName: this.fileName,
                  fileJson: this.currentOutput,
                  fileDir: `Captionings/Demonstration/English/${this.fileName}`
                },
                createdAt: currentTime,
                updatedAt: currentTime,
              }
              console.log(InsertObject)
              let added = await firebase.firestore().collection('closedCaptions').add(InsertObject);
              let admin = await firebase.auth().currentUser;
              //Clear old possible logs
              LogCreator.clearLog();
              //setup admin data
              LogCreator.setAdmin(admin)
              //setup event data
              LogCreator.setEvent('create', added.id)
              LogCreator.setChanges(InsertObject, [])
              //create log and assign Area
              const LogObject = LogCreator.create('Closed Caption (TR)', currentTime)
              let notificationObject = LogCreator.createNotification('System')
              //save into firestore
              await firebase.firestore().collection('HistoryLogs').add(LogObject)
              await firebase.firestore().collection('Notifications').add(notificationObject)
              this.$swal.fire({
                title: "Succefuly Saved",
                text: `New Closed Captioning was created.`,
                icon: "success",
              })
              this.patient = ""
              this.question = ""
              this.questionList = []
              this.fileName = ""
              this.uploadedMP3 = []
              this.currentOutput = []
            }else{
              let currentTime = firebase.firestore.Timestamp.now()
              //foreach file, create a new document
              for await (const file of this.uploadedMP3) {
                let fileName = file.name.replace(".mp3", ".txt");
                let InsertObject = {
                  child: "training",
                  name: fileName.replace(".txt", ""),
                  file: {
                    fileURL: file.output.texturl ? file.output.texturl : "",
                    fileName: fileName,
                    fileJson: file.output.json,
                    fileDir: `Captionings/Demonstration/English/${fileName}`
                  },
                  createdAt: currentTime,
                  updatedAt: currentTime,
                }
                let added = await firebase.firestore().collection('closedCaptions').add(InsertObject);
                let admin = await firebase.auth().currentUser;
                //Clear old possible logs
                LogCreator.clearLog();
                //setup admin data
                LogCreator.setAdmin(admin)
                //setup event data
                LogCreator.setEvent('create', added.id)
                LogCreator.setChanges(InsertObject, [])
                //create log and assign Area
                const LogObject = LogCreator.create('Closed Caption (TR)', currentTime)
                let notificationObject = LogCreator.createNotification('System')
                //save into firestore
                await firebase.firestore().collection('HistoryLogs').add(LogObject)
                await firebase.firestore().collection('Notifications').add(notificationObject)
              }
              this.$swal.fire({
                title: "Succefuly Saved",
                text: `New Closed Captioning was created.`,
                  icon: "success",
                })
                this.patient = ""
                this.question = ""
                this.questionList = []
                this.fileName = ""
                this.uploadedMP3 = []
                this.currentOutput = []
            }
          })
        } catch (error) {
          this.$swal.fire({
            title: "An error occured",
            error: error.message,
            text: "Please try again later",
            icon: "error",
          })
        }
      },
    },
}
 </script>
  